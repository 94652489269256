import Treasury from "../../assets/icons/Treasury.png";
import Account from "../../assets/icons/Accounts.png";
import Collect from "../../assets/icons/Collect.png";
import E_Doc from "../../assets/icons/E-Doc.png";
import E_Tax from "../../assets/icons/E-Tax.png";
import Wallets from "../../assets/icons/Wallets.png";

const Card =
  "w-full h-full border-2 rounded-3xl border-blue shadow-xl p-4 text-center shadow-blue";
const ImageDecorate = "w-10 lg:w-20 m-auto pt-4";

const TextDecorateInside = "text-blue font-bold text-lg md:text-xl pt-4";
const TextDecorationSpan = "text-sm text-center mt-2";

export const ProductCard_1 = () => {
  return (
    <div className={Card}>
      <img alt="Treasury" src={Treasury} className={ImageDecorate} />

      <p className={TextDecorateInside}>Teasury</p>
      <p className={TextDecorationSpan}>
        Run your business
        <br /> with total financial clarity.
      </p>
      <div></div>
    </div>
  );
};

export const ProductCard_2 = () => {
  return (
    <div className={Card}>
      <img alt="Account" src={Account} className={ImageDecorate} />

      <p className={TextDecorateInside}>Virtual Accounts</p>
      <p className={TextDecorationSpan}>
        Gain your full control of <br />
        Corporate payments with <br />
        real-time reconition.
      </p>
      <div></div>
    </div>
  );
};

export const HeaderCard_3 = () => {
  return (
    <div>
      <div className={Card}>
        <img alt="Collect" src={Collect} className={ImageDecorate} />

        <p className={TextDecorateInside}>Payment on behalf service</p>
        <p className={TextDecorationSpan}>
          Maker, Cheker, Approve System
          <br />
          Collect, Pay and Reconcile in real-time
          <br />
          Authorized service with
          <br />
          E-money and Bill payment licenses. Best for B2B payments with all{" "}
          <br />
          details and report
        </p>
      </div>
    </div>
  );
};

export const ProductCard_4 = () => {
  return (
    <div className={Card}>
      <img alt="E_Doc" src={E_Doc} className={ImageDecorate} />

      <p className={TextDecorateInside}>Invoice QR Integration</p>
      <p className={TextDecorationSpan}>Reconcile as you transact</p>
    </div>
  );
};

export const ProductCard_5 = () => {
  return (
    <div className={Card}>
      <img alt="Wallets" src={Wallets} className={ImageDecorate} />

      <p className={TextDecorateInside}>e-Wallets</p>
      <p className={TextDecorationSpan}>Master your payables and spend!</p>
    </div>
  );
};

export const SolutionCard_1 = () => {
  return (
    <div className={Card}>
      {/* <img alt="Wallets" src={Wallets} className={ImageDecorate} /> */}

      <p className={TextDecorateInside}>B2B Payments</p>
      <p className={TextDecorationSpan}>
        Payments Gateway <br />
        Merchant Management System Virtual Accounts
      </p>
    </div>
  );
};

export const SolutionCard_2 = () => {
  return (
    <div className={Card}>
      {/* <img alt="Wallets" src={Wallets} className={ImageDecorate} /> */}

      <p className={TextDecorateInside}>Order to Cash</p>
      <p className={TextDecorationSpan}>
        Faster collections and reconciliation <br />
        at lower cost Credit management{" "}
      </p>
    </div>
  );
};
export const SolutionCard_3 = () => {
  return (
    <div className={Card}>
      {/* <img alt="Wallets" src={Wallets} className={ImageDecorate} /> */}

      <p className={TextDecorateInside}>Record to Report</p>
      <p className={TextDecorationSpan}>
        Financial close management Real-time <br />
        reconciliation Anomaly management
      </p>
    </div>
  );
};
export const SolutionCard_4 = () => {
  return (
    <div className={Card}>
      {/* <img alt="Wallets" src={Wallets} className={ImageDecorate} /> */}

      <p className={TextDecorateInside}>Cash Management</p>
      <p className={TextDecorationSpan}>
        Completely automated daily cash positions. Optimize your debt, <br />
        investments and multi-currency accounts on a single click.
      </p>
    </div>
  );
};
export const SolutionCard_5 = () => {
  return (
    <div className={Card}>
      {/* <img alt="Wallets" src={Wallets} className={ImageDecorate} /> */}

      <p className={TextDecorateInside}>Account Payables</p>
      <p className={TextDecorationSpan}>
        Never miss a payment with payments setup with the system, integrated
        <br />
        with your ERP. Pay to any bank from a single portal.
      </p>
    </div>
  );
};
